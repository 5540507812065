import { TrelloStorage } from '@trello/storage';

import type { RegisteredDynamicConfigFlagKey } from './data/dynamicConfigFlags';
import { dynamicConfigFlags } from './data/dynamicConfigFlags';
import {
  CLIENT_STORAGE_KEY,
  OVERRIDES_STORAGE_KEY,
} from './dynamicConfig.constants';
import type { FlagSet } from './dynamicConfig.types';

export const dangerouslyGetDynamicConfigSync = (
  flagKey: RegisteredDynamicConfigFlagKey,
) => {
  const remoteData = TrelloStorage.get(CLIENT_STORAGE_KEY) ?? {};
  const remoteFlags: FlagSet = JSON.parse(remoteData.config ?? '{}');
  const overrides: FlagSet = TrelloStorage.get(OVERRIDES_STORAGE_KEY) || {};

  const keyRemote = remoteFlags[flagKey];
  const keyOverride = overrides[flagKey];
  const defaultFlagValue = dynamicConfigFlags[flagKey].defaultValue;

  const flagValue = keyOverride ?? keyRemote ?? defaultFlagValue;
  return flagValue;
};
