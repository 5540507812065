import { isEmbeddedDocument } from '@trello/browser';

import type {
  ErrorListener,
  ErrorListenerArgs,
  Listener,
  ListenerArgs,
} from '../types';
import { InMemoryStorage } from './InMemoryStorage';
import { isStorageAvailable } from './isStorageAvailable';
import type { STORAGE_KEYS_TYPE } from './storageKeys';
import { STORAGE_KEYS } from './storageKeys';

/**
 * Wrapper around the browser Storage APIs: localStorage and sessionStorage
 *
 * In special cases a fallback, in-memory store will be used. This should only
 * happen if Trello is being viewed within an embedded context and the browser
 * is preventing access to the Storage API
 */
export class StorageProxy {
  private storage: Storage;
  private listeners: Set<Listener>;
  private errorListeners: Set<ErrorListener>;
  private listenersSyncedAcrossBrowser: Set<Listener>;

  private LISTENER_IGNORABLE_KEY_PREFIXES = /^(awc\.)/;

  constructor(
    storageProviderKey: STORAGE_KEYS_TYPE = STORAGE_KEYS.LOCAL_STORAGE_KEY,
  ) {
    this.listeners = new Set();
    this.errorListeners = new Set();
    this.listenersSyncedAcrossBrowser = new Set();
    if (isEmbeddedDocument() && !isStorageAvailable(storageProviderKey)) {
      this.storage = new InMemoryStorage();
    } else {
      this.storage = window[storageProviderKey];
    }
    window.addEventListener('storage', this.onStorage);
  }

  onStorage = (e: StorageEvent) => {
    const { key, oldValue, newValue } = e;
    if (!key || key.match(this.LISTENER_IGNORABLE_KEY_PREFIXES)) {
      return;
    }

    this.broadcastChangeFromOtherInstance({ key, oldValue, newValue });
  };

  listen(listener: Listener) {
    this.listeners.add(listener);
  }

  addErrorListener(listener: ErrorListener) {
    this.errorListeners.add(listener);
  }

  removeErrorListener(listener: ErrorListener) {
    this.errorListeners.delete(listener);
  }

  listenSyncedAcrossBrowser(listener: Listener) {
    this.listenersSyncedAcrossBrowser.add(listener);

    // If we change localStorage in the current tab, but are only
    // listening for changes in other tabs, the current tab's
    // listeners won't get run. Thus, we add *all* browser-synced
    // listeners to the list of local listeners
    this.listen(listener);
  }

  unlisten(listener: Listener) {
    this.listeners.delete(listener);
    this.listenersSyncedAcrossBrowser.delete(listener);
  }

  broadcastLocalChange(args: ListenerArgs) {
    this.listeners.forEach((listener: Listener) => {
      listener(args);
    });
  }

  broadcastError(args: ErrorListenerArgs) {
    this.errorListeners.forEach((listener: ErrorListener) => {
      listener(args);
    });
  }

  broadcastChangeFromOtherInstance(args: ListenerArgs) {
    this.listenersSyncedAcrossBrowser.forEach((listener: Listener) => {
      listener(args);
    });
  }

  isEnabled() {
    return !!this.storage;
  }

  set(name: string, value: boolean | number | object | string) {
    try {
      if (this.storage) {
        const oldValue = this.getRaw(name);
        const newValue = JSON.stringify(value);
        this.storage.setItem(name, newValue);
        this.broadcastLocalChange({ key: name, oldValue, newValue });
      }
    } catch (error) {
      console.warn(error);
      this.broadcastError({
        key: name,
        error,
      });
    }
  }

  get(name: string) {
    let retVal = null;
    const rawValue = this.getRaw(name);
    if (rawValue) {
      try {
        retVal = JSON.parse(rawValue);
      } catch (ex) {
        console.warn(ex);
      }
    }

    return retVal;
  }

  getRaw(name: string): string | null {
    return this.storage ? this.storage.getItem(name) : null;
  }

  unset(name: string): void {
    if (this.storage && this.getRaw(name) !== null) {
      this.storage.removeItem(name);
    }
  }

  getAllKeys = () => {
    return this.storage ? Object.keys(this.storage) : [];
  };
}

// eslint-disable-next-line @trello/no-module-logic
export const TrelloStorage = new StorageProxy(STORAGE_KEYS.LOCAL_STORAGE_KEY);
// eslint-disable-next-line @trello/no-module-logic
export const TrelloSessionStorage = new StorageProxy(
  STORAGE_KEYS.SESSION_STORAGE_KEY,
);
