// Ensure that keys are sorted, to reduce merge conflicts
/* eslint sort-keys: "error" */

import type { SupportedFlagTypes } from '../dynamicConfig.types';

export type DynamicConfigFlagManifestValue = {
  createdOn: string;
  defaultValue: SupportedFlagTypes;
  owner: string;
};

/**
 * Feature flags in Statsig have to be defined here as well.
 * The `owner` field should be your Trello username.
 */
const dynamicConfigManifest = {
  tplat_max_first_idle_delay: {
    createdOn: '2024-10-23',
    defaultValue: 180000,
    owner: 'vkozachok',
  },
  trello_web_atlassian_team: {
    createdOn: '2024-11-13',
    defaultValue: false,
    owner: 'rwang3',
  },
  trello_web_billing_down_for_maintenance: {
    createdOn: '2024-11-08',
    defaultValue: false,
    owner: 'jmatthews',
  },
  trello_web_google_tag_manager: {
    createdOn: '2024-11-04',
    defaultValue: true,
    owner: 'mfaith',
  },
  trello_web_somethings_wrong: {
    createdOn: '2024-11-07',
    defaultValue: false,
    owner: 'mfaith',
  },
} as const;

export type RegisteredDynamicConfigFlagKey = keyof typeof dynamicConfigManifest;
// exporting the manifest as a separate object to type using the sealed const
export const dynamicConfigFlags: Record<
  RegisteredDynamicConfigFlagKey,
  DynamicConfigFlagManifestValue
> = dynamicConfigManifest;
