import { TrelloStorage } from '@trello/storage';

import type { RegisteredFeatureFlagKey } from './data/featureFlags';

/**
 * Types of feature flag values we support.
 */
declare type PrimitiveSupportedFlagTypes = boolean | number | string;
export declare type SupportedFlagTypes =
  | Array<PrimitiveSupportedFlagTypes>
  | Partial<{
      [key in RegisteredFeatureFlagKey]: PrimitiveSupportedFlagTypes;
    }>
  | PrimitiveSupportedFlagTypes;

type FlagSet = Partial<{
  [key in RegisteredFeatureFlagKey]: SupportedFlagTypes;
}>;

interface RecordedFeatureFlags {
  remote: FlagSet;
  overrides: FlagSet;
}

/**
 * Since we're fetching the value of `fep.native-current-board-full` using this method
 * multiple times, we want to make sure we get a consistent evaluation per page load.
 */
let memoizedCurrentBoardInfoFlagValue: SupportedFlagTypes | undefined =
  undefined;

/**
 * Reading recorded feature flag
 *
 * USE WITH CAUTION!!! Prefer non-falsy values.
 *
 * We are not recording 'falsy' values in snapshot (false, 0, null, '', etc). So there is a chance of:
 * * `override[key] == false` will not be picked if `remote[key] == true`
 * * if `remote[key] == false` and there is no override, **default** value will be served
 *
 * @param key
 * @param defaultValue
 * @returns
 */
export function getRecordedFeatureFlag(
  key: RegisteredFeatureFlagKey,
  defaultValue: SupportedFlagTypes,
): SupportedFlagTypes {
  const FEATURE_FLAGS_KEY = 'featureFlagSnapshot';
  const snapshot = TrelloStorage.get(FEATURE_FLAGS_KEY);
  const { remote = {}, overrides = {} }: RecordedFeatureFlags = snapshot ?? {};
  const keyOverride = overrides[key];
  const keyRemote = remote[key];

  const flagValue = keyOverride ?? keyRemote ?? defaultValue;

  // If this is the first time we're calling getRecordedFeatureFlag for 'fep.native-current-board-full',
  // memoize the value and use the saved value for future evaluations
  if (key === 'fep.native-current-board-full') {
    if (memoizedCurrentBoardInfoFlagValue === undefined) {
      memoizedCurrentBoardInfoFlagValue = flagValue;
    }
    return memoizedCurrentBoardInfoFlagValue;
  }

  return flagValue;
}
